import { Injectable } from '@angular/core';
import { createStore, setProps, withProps } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CourseInfoInterface } from '@shared/interfaces/course/course.interface';


export interface CourseProps {
  courseId: number | null;
  isCoursePage: boolean;
  enableSupportTab: boolean;
  hasAccess: boolean;
  courseName: string;
  slugId: string | null;
  courseInfo: CourseInfoInterface | null;
}

const name = 'course';
const store = createStore(
  { name },
  withProps<CourseProps>({
    courseId: null,
    isCoursePage: false,
    enableSupportTab: false,
    hasAccess: false,
    courseName: '',
    slugId: null,
    courseInfo: null
  })
);


@Injectable({
  providedIn: 'root',
})
export class CourseState {
  readonly state$ = store.pipe(
    filter((state) => !!state)
  ) as Observable<CourseProps>;

  constructor() {
  }

  get snapshot(): CourseProps {
    return store.getValue();
  }

  reset(): void {
    store.reset();
  }

  patch(value: Partial<CourseProps>): void {
    store.update(setProps(value));
  }

}
